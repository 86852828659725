<template>
  <!--<div>
    <a-button @click="changeKeys">获取</a-button>
    <a-button @click="changeKeys1">获取</a-button>
    &lt;!&ndash;<el-tree
        :check-strictly="true"
        :data="data"
        show-checkbox
        default-expand-all
        node-key="id"
        ref="tree"
        :default-checked-keys="selectKeys"
        :props="defaultProps">
    </el-tree>&ndash;&gt;
  </div>-->
  <div>
    <a-button @click="getKeys">获取</a-button>
    <a-tree
        v-model="checkedKeys"
        checkable
        :defaultExpandAll="true"
        :selected-keys="selectedKeys"
        :tree-data="treeData"
        @expand="onExpand"
        @select="onSelect"
        @check="onCheck"
    />
    <!--<a-tree
        checkable
        :defaultExpandAll="true"
        :tree-data="treeData"
        @check="onCheck"
    />-->
  </div>
</template>

<script>
    export default {
        name: "Test",
        data() {
            return {
                selectKeys:[],
                data: [{
                    id: 1,
                    label: '一级 1',
                    children: [{
                        id: 4,
                        label: '二级 1-1',
                        children: [{
                            id: 9,
                            label: '三级 1-1-1'
                        }, {
                            id: 10,
                            label: '三级 1-1-2'
                        }]
                    }]
                }, {
                    id: 2,
                    label: '一级 2',
                    children: [{
                        id: 5,
                        label: '二级 2-1'
                    }, {
                        id: 6,
                        label: '二级 2-2'
                    }]
                }, {
                    id: 3,
                    label: '一级 3',
                    children: [{
                        id: 7,
                        label: '二级 3-1'
                    }, {
                        id: 8,
                        label: '二级 3-2'
                    }]
                }],
                defaultProps: {
                    children: 'children',
                    label: 'label'
                },
                expandedKeys: ['0-0-0', '0-0-1'],
                autoExpandParent: true,
                checkedKeys: ['0-0-0-0'],
                selectedKeys: [],
                treeData: [
                    {
                        title: '0-0',
                        key: '0-0',
                        children: [
                            {
                                title: '0-0-0',
                                key: '0-0-0',
                                children: [
                                    {title: '0-0-0-0', key: '0-0-0-0'},
                                    {title: '0-0-0-1', key: '0-0-0-1'},
                                    {title: '0-0-0-2', key: '0-0-0-2'},
                                ],
                            },
                            {
                                title: '0-0-1',
                                key: '0-0-1',
                                children: [
                                    {title: '0-0-1-0', key: '0-0-1-0'},
                                    {title: '0-0-1-1', key: '0-0-1-1'},
                                    {title: '0-0-1-2', key: '0-0-1-2'},
                                ],
                            },
                            {
                                title: '0-0-2',
                                key: '0-0-2',
                            },
                        ],
                    },
                    {
                        title: '0-1',
                        key: '0-1',
                        children: [
                            {title: '0-1-0-0', key: '0-1-0-0'},
                            {title: '0-1-0-1', key: '0-1-0-1'},
                            {title: '0-1-0-2', key: '0-1-0-2'},
                        ],
                    },
                    {
                        title: '0-2',
                        key: '0-2',
                    },
                ]
            }
        },
        created() {

        },
        methods: {
            changeKeys(){
                this.selectKeys=[2,5]
            },
            changeKeys1(){
                this.selectKeys=[10]
            },
            getKeys() {
                console.log(this.checkedKeys)
            },
            onCheck(checkedKeys, info) {
                console.log('onCheck', checkedKeys);
                console.log('info', info);
                console.log( info.node.$options.propsData.eventKey);
                console.log( info.node.$options.propsData.checked);
                //获取所有子集


                //获取所有父母



                this.checkedKeys = checkedKeys;
            },
            onExpand(expandedKeys) {
                console.log('onExpand', expandedKeys);
                // if not set autoExpandParent to false, if children expanded, parent can not collapse.
                // or, you can remove all expanded children keys.
                this.expandedKeys = expandedKeys;
                this.autoExpandParent = false;
            },
            onSelect(selectedKeys, info) {
                console.log('onSelect', info);
                this.selectedKeys = selectedKeys;
            }
            ,
            onCheck1(checkedKeys, info) {
                console.log('onCheck', checkedKeys);
                console.log('info', info);
                this.checkedKeys = checkedKeys;
            }
            ,
            onSelect1(selectedKeys, info) {
                console.log('onSelect', info);
                this.selectedKeys = selectedKeys;
            }
            ,
        }
    }
</script>

<style scoped>

</style>
